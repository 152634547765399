.confirm_modal_wrap {
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  max-width: auto !important;
  border-radius: 12px;
  background: #fff;
  font-family: inherit;
  font-size: 16px;
  outline: 0;
  /* padding: 24px 26px 24px 26px; */
}

.wrapMoalLoad {
  min-width: 574px;
  min-height: 569px;
}

.title {
  display: flex;
  align-items: center;
}

.title svg {
  color: #c0c0c0;
}

.title p {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
  transition: all 0.6s ease-in-out;
  opacity: 1;
}

.wrapModal_title {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  background-color: #fff;
  z-index: 999;
  max-height: 60px;
  flex-wrap: nowrap;
}

.btnSearchMobile {
  border: none;
  background-color: transparent;
  padding: 0;
  min-width: 22px;
}

/* .btnSearchMobileActive {
    margin-left: 12px;
} */

.hiddenTitleText {
  display: none;

  transition: all ease-in-out;
}

.wrapModal_titleActive {
  display: flex;
  justify-content: space-around;
}

.wrapSearchMobile {
  display: flex !important;
  align-items: center;
  justify-content: flex-end !important;
  flex-wrap: unset;
}

.wrapModal_title > p {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #000000;
}

.inputV2PromoActive {
  min-width: 320px;
  width: 320px;
  opacity: 1;
  max-width: unset;
  margin-right: 8px;
  transition: all 0.3s ease-in-out;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px !important;
  margin: 0 auto !important;
}

.confirm_modal_wrap svg {
  cursor: pointer;
}

.counpon_list {
  /* overflow-y: scroll !important; */
  overflow-x: none !important;
  padding-top: 80px;
  max-width: 530px;
  border-radius: 12px !important;
  min-width: 390px;
  background-color: #f5f5f5;
}

.close {
  font-size: 24px;
  color: #c0c0c0;
}

.clearSearch {
  color: #c0c0c0;
  margin-right: 10px;
  font-size: 20px;
}

.wrapAction {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.inputV2Promo {
  opacity: 0;
  max-width: 0px;
  width: 0px;
}

.loadLogo {
  height: 495px;
  display: flex;
  justify-content: center;
}

.wrapTitleLoad {
  justify-content: space-between;
}

.button {
  background-color: #08ac60;
  color: #fff;
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end !important;
  padding: 10px 22px;
}

.loadingPopup {
  width: 530px;
  min-width: 390px;
}

.titleLoading {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.loadingLogo {
  display: flex;
  justify-content: center;
  min-height: 100% !important;
  min-width: 100%;
  align-items: center;
  height: 494px;
  width: 500px;
}

.wrapLoading {
  width: 100%;
  height: 100%;
}

.button:hover {
  background-color: #24bb74;
}

.counpon_list [class*='MuiGrid-spacing-xs-1'] {
  width: 100% !important;
}

.counpon_list [class*='infinite-scroll-component'] {
  overflow: unset !important;
}

.loadMore {
  text-align: center;
  font-family: ggsm;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.LoadCircle {
  color: #08ac60;
  animation-duration: 550ms;
  margin-right: 6px;
  margin-bottom: 4px;
}

.del_promo {
  cursor: pointer;
}

.not_yet {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100% 0;
  font-family: ggsm;
}

.hiddenLoad {
  display: none;
}

.dialogContent {
  padding: 0;
  border: unset;
  min-width: 100vw;
}

.unScrollY {
  overflow-y: hidden !important;
}

/* @media (min-width: 768px) {
    .confirm_modal_wrap {
        min-height: 580px;
    }
} */
.Notcode {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

@media (orientation: landscape) {
  .confirm_modal_wrap {
    min-height: 400px;
  }
}

@media (max-width: 599px) {
  .wrapBtnSearch {
    justify-content: flex-start;
    margin-top: 4px;
  }

  .inputV2Promo {
    max-width: unset;
  }
}

@media (max-width: 376px) {
  .voucherList {
    padding: 0px 14px;
  }

  .inputV2PromoActive {
    min-width: 280px;
    width: 280px;
  }
}
