.logo {
  min-width: 122px;
  margin-top: 2px;
}

.headerTop {
  background-color: #ffffff;
  color: #586189;
  border-bottom: 1px solid #e0e5f3;
}

.headerTop_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
}

.headerTopLink {
  margin-left: 28px;
}

.headerTopLink_content {
  color: #586189;
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.headerTopLink_content p {
  line-height: unset !important;
  font-family: googlesansregular !important;
}

.headerTopLink_content>div {
  margin-right: 4px !important;
}

.headerTopLink_content>span {
  margin-right: 4px !important;
}

.iconTopSeller {
  margin-bottom: 0px;
}

.headerCenter {
  background-color: #ffffff;
  padding: 0px 0px 5px 0px;
}

.headerCenter_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 45px;
  align-items: center;
  padding-top: 5px;
}

.headerCenter_Status {
  padding-right: 6px;
  width: 245px;
  display: flex;
  max-width: 245px;
  justify-content: space-between;
}

.headerCenter_Icon {
  background-color: transparent !important;
  color: #0e1983;
  font-weight: 500 !important;
}

.headerStatus {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.SearchInput {
  width: 100%;
}

.headerIconLogged {
  display: flex !important;
  align-items: center;
  max-width: 360px;
  justify-content: space-between;
  margin-left: auto;
}

.notice {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  position: relative;
}

.cart {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 40px; */
}

.cartLink {
  padding: 0 !important;
  margin-left: 40px;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info:hover {
  background-color: #ecf0fa;
  border-radius: 100%;
}

.notice:hover {
  background-color: #ecf0fa;
  border-radius: 50%;
  cursor: pointer;
}

.cart:hover {
  background-color: #ecf0fa;
  border-radius: 50%;
  cursor: pointer;
}

.cart+span {
  top: 6px;
  right: 6px;
  height: 16px;
  border: 1.5px solid #ffffff;
}

.info:hover {
  background-color: unset;
  border-radius: 100%;
  cursor: pointer;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
}

.avatar {
  flex-grow: 1;
  background-color: #ecf0fa;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar:hover {
  cursor: pointer;
}

.avatarIcon {
  width: 22px;
  height: 22px;
}

.profile_name {
  text-align: left;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 10px;
}

.profile_name>p {
  font-size: 15px;
  color: #202332;
  font-weight: 500;
  white-space: nowrap;
  width: 50px;
  font-family: googlesansmedium;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
  max-width: 90px;
}

.badge>span {
  top: 4px;
  right: 10px;
  height: 16px;
  width: auto;
  border: 1.5px solid #ffffff;
}

.headerCenter_wrapper .ins-preview-wrapper {
  display: none;
}

.notifyWrap {
  background: #ffffff;
  width: 400px;
  max-width: 100%;
  border-radius: 5px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  top: 86px !important;
  border-top: 0.5px solid #ececec;
}

.notifyWrap [class*='MuiMenu-list'] {
  padding: 0;
}

.notifyContentTop {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notifyTitle {
  margin: 0px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  font-family: googlesansregular;
}

.notifyStatus {
  margin: 0px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.57143;
  color: #a9a9a9;
  font-family: googlesansregular;
}

.markAll {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(0, 171, 85);
}

.notificationsItem {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 12px 20px !important;
  border-bottom: 1px solid rgba(145, 154, 163, 0.3);
}

.notificationsItem:hover {
  background-color: rgb(143 253 198 / 8%) !important;
}

.read {
  background-color: #ffffff !important;
}

.unRead {
  font-weight: bold !important;
}

.notifyIcon {
  max-width: 15%;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #fff;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 20px;
  background-color: #56ccf2;
}

.notifyContent {
  max-width: 80%;
  width: 100%;
  margin-left: 10px;
}

.notifyContentTitle {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.57143;
  color: rgba(0, 0, 0, 0.87);
  font-family: googlesansregular;
}

.notifyContentDescription {
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.43;
  font-family: googlesansregular;
  color: rgba(0, 0, 0, 0.54);
}

.createdAt {
  margin-top: 4px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: googlesansregular;
  color: rgb(145, 158, 171);
}

.viewAll {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64px;
  padding: 6px 8px !important;
  width: 100%;
}

.viewAll:hover {
  background-color: rgba(0, 171, 85, 0.08) !important;
}

.viewAllTitle {
  line-height: 1.7 !important;
  color: #00ab55 !important;
  font-family: googlesansregular;
  font-size: 14px !important;
}

.buttonStyle {
  color: #0e1983;
}

.countdownBarHalfContainer {
  max-width: 1304px !important;
}

.countdownBarHalfWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdownBarHalf {
  display: flex;
  justify-content: space-between;
  width: calc(81% + 6px);
  aspect-ratio: 1920/96;
}

.countdownBarHalfLimit {
  width: 47%;
  position: relative;
}

.countdownBarFullWrapper {
  width: 100%;
  position: relative;
  aspect-ratio: 1920/80;
}

/* .searchIconRes {
  display: none;
} */
/* responsive */
@media (max-width: 900px) {
  .profile_name {
    display: none;
  }
}

@media (max-width: 680px) {
  .headerTop_wrapper {
    justify-content: center;
  }
}

@media (max-width: 540px) {
  .headerTopLink {
    margin-left: 12px;
  }
}

@media (max-width: 480px) {
  .headerTopLink {
    margin-left: 4px;
    font-size: 14px;
  }

  .headerTopLink span {
    display: none !important;
  }

  .headerTopLink svg {
    display: none !important;
  }

  .cartLink {
    margin-left: 0px;
  }

  .headerStatus {
    margin-left: 0;
  }

  .headerCenter_Status {
    justify-content: flex-end;
  }

  .buttonStyle svg {
    display: none;
  }

  .buttonStyle {
    margin-left: 10px;
    font-size: 13px;
  }

  .cart {
    margin-left: 20px;
  }

  .notifyWrap {
    width: 342px;
    max-width: 342px;
  }

  .profile {
    margin-left: 12px;
  }

  .headerTopLink .headerTopLink_content p {
    font-size: 13.5px;
  }

  .headerTop_wrapper {
    /* justify-content: space-between; */
    padding: 0 !important;
  }

  .iconTopSeller {
    margin-bottom: 0;
  }
}

@media (max-width: 340px) {
  .cart {
    margin-left: 10px;
  }

  .headerTop_wrapper {
    padding: 0 !important;
  }

  .headerTop_wrapper {
    justify-content: space-between;
  }

  .buttonStyle {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .profile {
    margin-left: 12px;
  }

  .cart {
    margin-left: 0;
  }

  .notifyWrap {
    width: 290px;
  }
}