.headerMobile {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 10px 0px 15px;
  z-index: 999;
}

.removeBoxShadow {
  box-shadow: unset;
}

.headerTop_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  height: 48px;
}

.headerTop_wrapper>div {
  align-items: center;
}

.headerTop_wrapper>a {
  padding: 15px;
}

.headerTitle {
  padding: 0 8px;
  font-weight: 500;
}

.rightIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.rightIcon>span {
  padding: 0 1rem;
}

.rightIcon button {
  height: 48px;
  padding: 0;
}

.notice,
.cart {
  display: flex;
}

.profile {
  display: flex;
  align-items: center;
}

/* Store header */
.headerStore {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  text-transform: capitalize;
  font-family: ggsm;
}

.gridSpacing {
  text-align: right;
}

.gridSpacing button:first-child {
  margin-right: 8px;
}

.searchAndFollow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchAndFollow>button:first-child {
  padding-right: 16px;
}

.avatarIcon {
  width: 27px;
  height: 27px;
}

.badge>span {
  justify-content: center;
}

.boxCartTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.greenIcon span {
  filter: invert(10%) sepia(71%) saturate(2565%) hue-rotate(242deg) brightness(59%) contrast(66%);
}

.mobileDisplay_switcher p {
  font-size: 12px;
  font-family: ggsm;
  color: #000;
}

.containerLeftContent {
  display: flex;
  align-items: center;
}

.titleHeader {
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: 'ggsm';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.rightContent {
  flex-shrink: 0;
  /* align-items: flex-end; */
}

.btnBack {
  padding: 8px 8px 8px 0;
  min-width: 0 !important;
  width: 35px !important;
  display: flex;
  transform: translateX(-5px);
}

.mobileHeaderButton_textAlign {
  text-align: end;
}

.lowercase {
  text-transform: lowercase !important;
}