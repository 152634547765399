.root {
  padding: 10px 38px !important;
  font-size: 1rem !important;
  font-family: googlesansregular !important;
}
.root:hover {
  color: #00b46e !important;
}

.root:hover svg {
  color: #00b46e !important;
}
@media screen and (min-height: 580px) {
  .root {
    padding: 10px 44px !important;
  }
}
