.gridContainer {
  height: 100%;
}
.search_wrap {
  height: 48px;
  transition: all 1s ease-in-out;
  background-color: #fff;
  padding: 0 15px;
}
.search_wrap > div {
  align-items: center;
}
.gridSearch_container {
  display: flex;
  align-items: center;
}
.pageTitle {
  font-size: 16px;
  font-weight: 500;
  font-family: ggsm;
  text-align: left;
  line-height: 20px;
}
.btnBack {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
}
.btnBack > span:first-child > span {
  width: 16px !important;
}

/* Modal Content - Noti */
.modalContent_container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  max-height: calc(100% - 48px);
  overflow-y: scroll;
  z-index: 610;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  left: 0;
  z-index: 98;
  height: 100%;
}

/* Modal Content - Cart */
.modalCart_container {
  padding: 0;
}
.modalCart_container > div {
  margin-bottom: 1rem;
}

.modalCart_box {
  border-bottom: 1px solid rgba(195, 204, 220, 0.4);
  background-color: #fff;
}

.modalCart_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.modalCart_header button {
  padding: 12px 0;
}
.modalCart_header button > span {
  gap: 8px;
}
.modalCart_header p {
  color: #797979;
  font-size: 14px;
  font-family: ggsr;
  line-height: 18px;
}
.modalCart_header button span p {
  font-family: ggsm;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  font-weight: 500;
  color: #000;
}
.modalCart_icon {
  width: 32px;
  height: 32px;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  padding: 2px;
}
.modalCart_body {
  background: white;
  height: 300px;
}

.markAll {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 0;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(0, 171, 85);
}
