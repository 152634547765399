.bg_mobile {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

/* new mobile ui v2 */
.new_bg_mobile {
  width: 100%;
  height: 100%;
  overflow: unset !important;
  -webkit-overflow-scrolling: touch;
}

.btngroup {
  text-align: center;
  margin: 16px auto;
}

.btngroup .custombtn {
  color: #00b46e !important;
  background-color: transparent !important;
  border: 1px solid #00b46e !important;
  margin-right: 10px !important;
}

.text_modal {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.text_modal a {
  color: #00b46e !important;
}

@media (max-width: 767px) {
  .btngroup button {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    margin-right: 0 !important;
  }
}

.appBar {
  top: auto !important;
  bottom: 0;
  background-color: #fff !important;
  color: #919aa3 !important;
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.08);
}