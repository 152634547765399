.table_head {
  color: #212529;
  padding: 0.75rem !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.table {
  min-width: 650px;
  border-radius: 30px !important;
}

.table [class*='MuiTableRow-head'] {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
