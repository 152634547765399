.root_input [class*='MuiInputBase-input'] {
  font-weight: 500 !important;
  font-size: 14px !important;
  background-color: white;
  min-width: 0;
  border-radius: 2rem;
  border: 1px solid #e0e5f3 !important;
  width: 100% !important;
  height: 40px;
  /* padding-left: 48px !important;
  padding-top: 9px; */
  padding: 0px 15px 0px 48px;
  color: #4753a8 !important;
  text-overflow: ellipsis;
  font-family: googlesansmedium;
  -webkit-appearance: none;
}
.root_input_tablet [class*='MuiInputBase-input'] {
  font-weight: 500 !important;
  font-size: 14px !important;
  background-color: white;
  min-width: 0;
  width: 100% !important;
  height: 40px;
  /* padding-left: 30px !important;
  padding-top: 9px; */
  padding: 9px 15px 0px 30px;
  color: #4753a8 !important;
  text-overflow: ellipsis;
  font-family: googlesansmedium;
}

.root_input_tablet [class*='MuiInputBase-inputTypeSearch'] {
  -webkit-appearance: none;
}

.root_input [class*='MuiInputBase-inputTypeSearch'] {
  -webkit-appearance: none;
}

.input {
  text-align: left;
  color: #a6aed2;
  font-size: 12px;
}
.HeaderSearch {
  width: 100% !important;
}
.SearchInput {
  width: 100%;
}
.search_wrap {
  max-width: 800px;
  width: 100%;
  z-index: 1000;
  min-width: 25em;
  position: relative;
}
.search_wrap [class*='MuiInput-underline']::after {
  border: none !important;
}
.search_wrap [class*='MuiInput-underline']::before {
  border: none !important;
}
.search_wrap [class*='MuiInputAdornment-positionStart'] {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  margin: 0px;
}
.search_ic_tablet [class*='MuiInputAdornment-positionStart'] {
  left: 0px !important;
}
@media (max-width: 805px) {
  .search_wrap {
    display: none;
  }
}
.selectSearch {
  border-radius: 0rem 2rem 2rem 0rem;
}
.formControl {
  width: 160px;
}
.formControlTablet {
  width: 170px;
}
.selectInput {
  font-family: ggsr;
  font-size: 14px;
  color: #586189;
}
.selectInput select {
  border: 1px solid #e0e5f3;
  height: 40px;
  border-radius: 0px 40px 40px 0px !important;
  padding-left: 10px;
  padding-top: 9px;
}
.selectInput svg {
  top: calc(50% - 11px);
  margin-right: 10px;
  color: #586189;
  width: 0.8em;
}
.selectInputFocus {
  font-family: ggsr;
  font-size: 14px;
  color: #586189;
}
.selectInputFocus select {
  border: 1px solid #e0e5f3;
  background-color: #fff !important;
  height: 40px;
  border-radius: 0px 40px 40px 0px !important;
  padding-left: 10px;
  padding-top: 9px;
}
.selectInputFocus svg {
  top: calc(50% - 11px);
  color: #00b46e;
  margin-right: 10px;
  width: 0.8em;
}
.hiddenSelect {
  display: none !important;
}
/* .stickySelect {
  animation: showWidthSelect ease 0.8s;
}
@keyframes showWidthSelect {
  0% {
    width: 0% !important;
  }
  100% {
    width: 18% !important;
  }
} */
.stickySelect .formControl {
  width: 160px !important;
  border-radius: 0px 40px 40px 0px !important;
}
.stickySelect .selectInputFocus {
  background-color: #fff;
  border-radius: 0px 40px 40px 0px !important;
}
.stickySelect select {
  background-color: #fff !important;
}
.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% !important;
  height: 40px;
}
.searchTabletContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% !important;
  height: 40px;
  border-radius: 40px !important;
  box-shadow: 0 0 0 1px #e0e5f3;
}
.root_input_sticky [class*='MuiInputBase-input'] {
  border-radius: 2rem !important;
}
.root_input_scale {
  width: 100% !important;
}
.text_field_tablet {
  width: 100% !important;
  padding-left: 15px;
}
.focusSelect select {
  background-color: #fff !important;
}
.border_search_container {
  outline: 1px solid #0e1983;
  border-radius: 40px;
}
.border_search_tablet {
  border: none;
  outline: none;
  border-radius: 40px !important;
  box-shadow: 0 0 0 1px #0e1983;
}
.border_tablet {
  outline: 1px solid #0e1983;
}
.border_none select {
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
}
.err_border {
  outline: 1px solid #d4323b !important;
  border-radius: 40px;
}
