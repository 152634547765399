.notify_container {
  display: flex;
  gap: 10px;
}
.notifyStatus {
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.57143;
  color: #a9a9a9;
  font-family: googlesansregular;
  text-align: center;
  padding: 12px;
}
.notificationsItem {
  padding: 16px 0 !important;
  border-bottom: 1px solid rgba(195, 204, 220, 0.4);
  display: block !important;
  color: #343a40;
  transition: color 0.2s, background-color 0.2s;
  font-size: 16px !important;
  word-break: break-word;
}

.notify_icon {
  padding: 4px;
  background: #58cbef;
  height: 30px;
  width: 30px;
}
.notify_icon svg {
  fill: #fff;
  transform: scale(0.7);
}

.notiContainer_info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.notiContainer_info p:last-child {
  color: rgba(52, 58, 64, 0.8);
}

.viewAll_container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  margin-top: 90px;
}
.viewAll {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  font-weight: 700;
  font-size: 0.875rem !important;
  line-height: 1.71429;
  text-transform: capitalize;
  min-width: 64px;
  padding: 12px 20px !important;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(0, 171, 85) !important;
  width: 100%;
}
