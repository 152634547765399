.searchDropdown {
  width: 100%;
  min-width: 20em;
  max-height: 20em;
  margin-top: 0.5rem !important;
  z-index: 1000 !important;
  background-color: #ffffff;
  border-radius: 10px;
  /* border: 1px solid #e0e5f3; */
  overflow-y: scroll;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.keyword {
  color: rgb(0, 0, 0);
  font-size: 14px !important;
  font-family: googlesansregular;
}

.searchResults {
  padding: 10px !important;
  display: block !important;
  color: #343a40;
  transition: all 0.1s ease-in;
  font-size: 14px !important;
  word-break: break-word;
  font-family: googlesansregular;
  text-transform: capitalize;
}

.searchResults:hover {
  background-color: #e7fbf1;
  text-decoration: none;
  transition: color 0.1s, background-color 0.1s;
}

.searchResults:hover a {
  color: #00b46e !important;
}

.textPrimary {
  color: #00b46e !important;
  font-weight: 500;
  font-family: googlesansmedium;
}

@media screen and (max-width: 980px) {
  .searchDropdown {
    min-width: 14em;
  }
}